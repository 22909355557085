export const data = {
  "clients": [
    "client_2.png",
    "client_2df.png",
    "client_abc.png",
    "client_ard.png",
    "client_bbc.png",
    "client_cbc.png",
    "client_dr.png",
    "client_espn.png",
    "client_kbs.png",
    "client_nhkworld.png",
    "client_org.png",
    "client_rtl.png",
    "client_sky.png",
    "client_sporttv.png",
    "client_srgsrsr.png",
    "client_tsn.png",
    "client_tvglobo.png",
    "client_univision.png",
    "client_yle.png",
  ],
}